import React from "react";
import { Navigate } from "react-router-dom";

const AdminProtected = (props) => {
  if (localStorage.getItem("authUser")) {
    if (JSON.parse(localStorage.getItem("authUser")).role === "Admin") {
      return (<React.Fragment>
        {props.children}
      </React.Fragment>);
    }
    else {
      return (
        <Navigate to={{ pathname: "/dashboard", state: { from: props.location } }} />
      );
    }
  }
  else {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
};

export default AdminProtected;
