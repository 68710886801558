import {
  GET_JOB_LIST,
  GET_JOB_LIST_FAIL,
  GET_JOB_LIST_SUCCESS,
  ADD_NEW_PORTFOLIO,
  ADD_PORTFOLIO_SUCCESS,
  ADD_PORTFOLIO_FAIL,
  UPDATE_JOB_LIST,
  UPDATE_JOB_LIST_SUCCESS,
  UPDATE_JOB_LIST_FAIL,
  DELETE_JOB_LIST,
  DELETE_JOB_LIST_SUCCESS,
  DELETE_JOB_LIST_FAIL,
  GET_APPLY_JOB,
  GET_APPLY_JOB_SUCCESS,
  GET_APPLY_JOB_FAIL,
  DELETE_APPLY_JOB,
  DELETE_APPLY_JOB_SUCCESS,
  DELETE_APPLY_JOB_FAIL,
  GET_USER_LIST,
  GET_USER_LIST_FAIL,
  GET_USER_LIST_SUCCESS,
  SET_PROPERTY_FOR_DETAILS,
  GET_PROPERTY_FOR_DETAILS,
  BACK_TO_PROPERTIES,
  ADD_TO_CHATS,
  ADD_TO_CHATS_FAIL,
  ADD_TO_CHATS_SUCCESS,
  PROCESS_QUERY,
  PROCESS_QUERY_FAIL,
  PROCESS_QUERY_SUCCESS,
  RESET_CHATS,
  RESET_TOAST
} from "./actionTypes";

export const resetToast = () => {
  return {
    type: RESET_TOAST
  };
}

export const resetChat = () => {
  return {
    type: RESET_CHATS
  };
}

export const addToChats = (data) => {
  return {
    type: ADD_TO_CHATS,
    payload: { data }
  };
}

export const addToChatsSuccess = data => {
  return {
    type: ADD_TO_CHATS_SUCCESS,
    payload: data
  }
}

export const addToChatsFail = error => ({
  type: ADD_TO_CHATS_FAIL,
  payload: error,
})

export const setBackToProperties = (setPage) => {
  return {
    type: BACK_TO_PROPERTIES,
    setPage: setPage
  };
}

export const setPropertyForDetails = (data) => {
  return {
    type: SET_PROPERTY_FOR_DETAILS,
    payload: data
  };
}

export const getPropertyForDetails = (data) => {
  return {
    type: GET_PROPERTY_FOR_DETAILS,
    payload: { data }
  };
}

export const getJobList = (data) => {
  return {
    type: GET_JOB_LIST,
    payload: { data }
  };
}

export const getJobListSuccess = jobData => {
  return {
    type: GET_JOB_LIST_SUCCESS,
    payload: jobData,
  }
}

export const getJobListFail = error => ({
  type: GET_JOB_LIST_FAIL,
  payload: error,
})

export const getUserList = (data) => {
  return {
    type: GET_USER_LIST,
    payload: { data }
  };
}

export const getUserListSuccess = userData => {
  return {
    type: GET_USER_LIST_SUCCESS,
    payload: userData,
  }
}

export const getUserListFail = error => ({
  type: GET_USER_LIST_FAIL,
  payload: error,
})

export const addNewPortfolio = data => ({
  type: ADD_NEW_PORTFOLIO,
  payload: data,
})

export const addPortfolioSuccess = () => ({
  type: ADD_PORTFOLIO_SUCCESS
})

export const addPortfolioFail = error => ({
  type: ADD_PORTFOLIO_FAIL,
  payload: error,
})

export const processQuery = (modelId, query) => ({
  type: PROCESS_QUERY,
  payload: { modelId, query },
})

export const processQuerySuccess = query => ({
  type: PROCESS_QUERY_SUCCESS,
  payload: query,
})

export const processQueryFail = error => ({
  type: PROCESS_QUERY_FAIL,
  payload: error,
})

export const updateJobList = job => ({
  type: UPDATE_JOB_LIST,
  payload: job,
})

export const updateJobListSuccess = job => ({
  type: UPDATE_JOB_LIST_SUCCESS,
  payload: job,
})

export const updateJobListFail = error => ({
  type: UPDATE_JOB_LIST_FAIL,
  payload: error,
})

export const deleteJobList = data => ({
  type: DELETE_JOB_LIST,
  payload: data,
})

export const deleteJobListSuccess = data => ({
  type: DELETE_JOB_LIST_SUCCESS,
  payload: data,
})

export const deleteJobListFail = error => ({
  type: DELETE_JOB_LIST_FAIL,
  payload: error,
})

export const getApplyJob = (data) => {
  return {
    type: GET_APPLY_JOB,
    payload: data,
  }
}

export const getApplyJobSuccess = data => ({
  type: GET_APPLY_JOB_SUCCESS,
  payload: data
})

export const getApplyJobFail = error => ({
  type: GET_APPLY_JOB_FAIL,
  payload: error
})

export const deleteApplyJob = data => ({
  type: DELETE_APPLY_JOB,
  payload: data
})

export const deleteApplyJobSuccess = data => ({
  type: DELETE_APPLY_JOB_SUCCESS,
  payload: data
})

export const deleteApplyJobFail = error => ({
  type: DELETE_APPLY_JOB_FAIL,
  payload: error
})