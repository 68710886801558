import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
} from "reactstrap"
import { deleteUser, resetUser, updateClickCount, updateUser } from "store/actions"
import { createSelector } from "reselect"

const CardContact = (props) => {
  const { user, cardKey, element } = props

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [training, setTraining] = useState(props?.user?.trainingInprogress)
  const [isAdmin, setIsAdmin] = useState(false);

  const selectContactsState = state => state.contacts
  const ContactsProperties = createSelector(selectContactsState, Contacts => ({
    users: Contacts.users,
  }))

  const { users } = useSelector(ContactsProperties)

  const startTraining = () => {
    setTraining(true)

    let updatedUser = { ...user }
    dispatch(updateUser(updatedUser))
  }

  const deleteModel = (user) => {
    let updatedUser = { ...user }
    dispatch(deleteUser(updatedUser))
  }

  useEffect(() => {
    const loggedIn = JSON.parse(localStorage.getItem("authUser"));
    if (loggedIn && loggedIn?.role && loggedIn?.role === "Admin") {
      setIsAdmin(true);
    }
    else {
      setIsAdmin(false);
    }
  }, [])

  return (
    <React.Fragment>
      <Col xl="3" sm="6">
        <Card className="text-center">
          <CardBody>
            {!user?.img ? (
              <div className="avatar-sm mx-auto mb-4">
                <span
                  className={
                    "avatar-title rounded-circle bg-primary-subtle bg-" +
                    user?.color +
                    " text-" +
                    user?.color +
                    " font-size-16"
                  }
                >
                  {user?.name.charAt(0)}
                </span>
              </div>
            ) : (
              <div className="mb-4">
                <img
                  className="rounded-circle avatar-sm"
                  src={user.img}
                  alt=""
                />
              </div>
            )}

            <h5 className="font-size-15 mb-1 text-dark">{user?.name}</h5>
            <p className="text-muted">{ }</p>

            <div>
              {user?.isTrained && (
                <Badge color="danger" className="me-1">
                  Trained
                </Badge>
              )}
              {user?.isActive && (
                <Badge color="warning" className="me-1">
                  Active
                </Badge>
              )}
            </div>
          </CardBody>
          {element !== "course" ? (
            <CardFooter className="bg-transparent border-top">
              <div className="contact-links font-size-20">
                <div className="d-sm-flex align-items-center justify-content-between">
                  {!training ? (
                    <Button
                      disabled={user?.isTrained || !user?.readyToTrain}
                      color="link"
                      type="button"
                      style={{ padding: "0px 5px" }}
                      id={`${user?.name}-train`}
                      onClick={() => {
                        startTraining()
                      }}
                    >
                      <i
                        className="bx bx-run"
                        style={{ fontSize: "large", color: "black" }}
                        id={`${cardKey}-icon-train`}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`${cardKey}-icon-train`}
                      >
                        Train Model
                      </UncontrolledTooltip>
                    </Button>
                  ) : (
                    <Button
                      color="link"
                      type="button"
                      style={{ padding: "0px 5px" }}
                      id={`${user?.name}-train`}
                      onClick={() => {
                        return
                      }}
                    >
                      <i
                        className="bx bx-loader bx-spin"
                        style={{ fontSize: "medium", color: "black" }}
                      />
                    </Button>
                  )}
                  <Button
                    disabled={training || user?.isTrained}
                    color="link"
                    type="button"
                    style={{ padding: "0px 5px" }}
                    id={`${user?.name}-edit`}
                    onClick={() => {
                      dispatch(resetUser(user))
                      navigate("/customize-model")
                    }}
                  >
                    <i
                      className="bx bx-edit-alt"
                      style={{ fontSize: "large", color: "black" }}
                      id={`${cardKey}-icon-update`}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`${cardKey}-icon-update`}
                    >
                      Update Model
                    </UncontrolledTooltip>
                  </Button>
                  <Button
                    disabled={training || user?.isTrained}
                    color="link"
                    type="button"
                    style={{ padding: "0px 5px" }}
                    id={`${user?.name}-delete`}
                    onClick={() => {
                      deleteModel(user)
                    }}
                  >
                    <i
                      className="bx bx-trash-alt"
                      style={{ fontSize: "large", color: "black" }}
                      id={`${cardKey}-icon-delete`}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`${cardKey}-icon-delete`}
                    >
                      Delete Model
                    </UncontrolledTooltip>
                  </Button>
                </div>
              </div>
            </CardFooter>
          ) : (
            <CardFooter className="bg-transparent border-top">
              <div className="contact-links font-size-20">
                <div className="align-items-center">
                  <Button
                    color="link"
                    type="button"
                    disabled={training}
                    style={{ padding: "0px 5px" }}
                    id={`${user?.name}-edit`}
                    onClick={() => {
                      if (isAdmin) return;
                      dispatch(updateClickCount(user))
                      navigate(`/public-profile/${user?.userName}`)
                    }}
                  >
                    {isAdmin ?
                      <i
                        className="bx bx-info-circle"
                        style={{ fontSize: "large", color: "black" }}
                        id={`${cardKey}-icon-update`}
                      /> : <i
                        className="bx bx-expand"
                        style={{ fontSize: "large", color: "black" }}
                        id={`${cardKey}-icon-update`}
                      />
                    }
                    <UncontrolledTooltip
                      placement="top"
                      target={`${cardKey}-icon-update`}
                    >
                      {!isAdmin ? 'Expand Course' : `Total Counts: ${user?.clickCount}`}
                    </UncontrolledTooltip>
                  </Button>
                </div>
              </div>
            </CardFooter>
          )}
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardContact.propTypes = {
  user: PropTypes.object,
  cardKey: PropTypes.string,
  element: PropTypes.string,
}

export default CardContact
