import React, { useEffect, useState } from "react"
import withRouter from "components/Common/withRouter"
import { Button, Col, Container, Row, Spinner } from "reactstrap"
import { map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Card
import CardContact from "./card-contact"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import { getCourses } from "../../store/contacts/actions"

const CourseGrid = () => {
  //meta title
  document.title = "Course Grid | Skill Mentors | Learn Earn Build"

  const dispatch = useDispatch()
  const [spin, setSpin] = useState(false)

  const selectContactsState = state => state.contacts
  const ContactsProperties = createSelector(selectContactsState, Contacts => ({
    courses: Contacts.courses,
    loading: Contacts.loading,
  }))

  const { courses, loading } = useSelector(ContactsProperties)

  useEffect(() => {
    dispatch(getCourses())
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Skill Mentors" breadcrumbItem="Course Grid" />
          {loading ? (
            <Spinner
              type="grow"
              className="ms-n3"
              style={{ position: "relative", left: "50%" }}
              color="secondary"
            />
          ) : (
            <>
              <Row>
                {map(courses, user => (
                  <CardContact
                    user={user}
                    cardKey={"_course_" + user?._id}
                    element={"course"}
                  />
                ))}
              </Row>
              <Row>
                <Col xs="12">
                  <div className="text-center my-3">
                    <Button
                      className="text-warning"
                      color="link"
                      type="button"
                      onClick={() => {
                        setSpin(true)
                        setTimeout(() => {
                          setSpin(false)
                        }, 5000)
                      }}
                    >
                      {spin && <i className="bx bx-hourglass bx-spin me-2" />}
                      {!spin && <i className="bx bx-hourglass me-2" />}
                      Load more
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CourseGrid)
