import { messages, users } from "common/data"
import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  RESET_USER,
  UPLOAD_FILES,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_FAIL,
  GET_COURSES,
  GET_COURSES_SUCCESS,
  GET_COURSES_FAIL,
  RESET_MESSAGES,
  UPDATE_CLICK_COUNT,
  DELETE_FILES,
  DELETE_FILES_SUCCESS,
  DELETE_FILES_FAIL
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  courses: [],
  userProfile: {},
  error: {},
  loading: false,
  user: {},
  response: {},
}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_USER:
      return {
        ...state,
        user: action.payload || {},
      }

    case UPDATE_CLICK_COUNT:
      return {
        ...state,
        user: action.payload,
      }

    case DELETE_FILES:
      return {
        ...state,
        loading: true,
      }

    case DELETE_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case DELETE_FILES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case UPLOAD_FILES:
      return {
        ...state,
        loading: true,
      }

    case UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case UPLOAD_FILES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case GET_USERS:
      return {
        ...state,
        loading: true,
      }

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_COURSES:
      return {
        ...state,
        loading: true,
      }

    case GET_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.payload,
        loading: false,
      }

    case GET_COURSES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case ADD_NEW_USER:
      return {
        ...state,
        loading: true,
      }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_USER_PROFILE:
      return {
        ...state,
        loading: true,
      }

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        userProfile: action.payload,
      }

    case UPDATE_USER:
      return {
        ...state,
      }

    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        response: action.payload.response,
        users: state.users.map(user =>
          user._id === action.payload.user._id ? action.payload.user : user
        ),
      }
    }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case RESET_MESSAGES:
      return {
        ...state,
        error: action.payload || {},
        response: action.payload || {},
      }

    case DELETE_USER:
      return {
        ...state,
        loading: true,
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.filter(
          user => user._id !== action.payload._id
        ),
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default contacts
