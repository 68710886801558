import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_JOB_LIST,
  UPDATE_JOB_LIST,
  DELETE_JOB_LIST,
  GET_APPLY_JOB,
  DELETE_APPLY_JOB,
  GET_USER_LIST,
  ADD_TO_CHATS,
  ADD_NEW_PORTFOLIO,
  PROCESS_QUERY,
  PROCESS_QUERY_SUCCESS,
  PROCESS_QUERY_FAIL,
} from "./actionTypes"
import {
  getJobListSuccess,
  getJobListFail,
  addJobListSuccess,
  addJobListFail,
  updateJobListSuccess,
  updateJobListFail,
  deleteJobListSuccess,
  deleteJobListFail,
  getApplyJobSuccess,
  getApplyJobFail,
  deleteApplyJobSuccess,
  deleteApplyJobFail,
  getUserListFail,
  getUserListSuccess,
  addToChatsSuccess,
  addToChatsFail,
  addPortfolioSuccess,
  addPortfolioFail,
  processQuerySuccess,
  processQueryFail,
} from "./actions"

import {
  getJobList,
  addNewPortfolio,
  updateJobList,
  deleteJobList,
  getApplyJob,
  deleteApplyJob,
  getUserList,
  addToChatsServer,
  processQueryOnServer,
} from "helpers/fakebackend_helper"
import { toast } from "react-toastify"

function* addToChatsNow({ payload: { data } }) {
  try {
    const response = yield call(addToChatsServer, data)
    localStorage.setItem("chatId", response._id)
    yield put(addToChatsSuccess(response))
  } catch (error) {
    yield put(addToChatsFail(error))
  }
}

function* processQuery({ payload: { modelId, query } }) {
  try {
    const response = yield call(processQueryOnServer, { modelId, query })
    yield put(processQuerySuccess(response))
  } catch (error) {
    yield put(processQueryFail(error))
  }
}

function* fetchJobsList({ payload: { data } }) {
  try {
    const response = yield call(getJobList, data)
    yield put(getJobListSuccess(response))
  } catch (error) {
    yield put(getJobListFail(error))
  }
}

function* fetchUsersList({ payload: { data } }) {
  try {
    const response = yield call(getUserList, data)
    yield put(getUserListSuccess(response))
  } catch (error) {
    yield put(getUserListFail(error))
  }
}

function* onAddNewPortfolio({ payload: data }) {
  try {
    const response = yield call(addNewPortfolio, data)
    yield put(addPortfolioSuccess(response))
  } catch (error) {
    yield put(addPortfolioFail(error))
  }
}

function* onUpdateJobList({ payload: data }) {
  try {
    const response = yield call(updateJobList, data)
    yield put(updateJobListSuccess(response))
    toast.success("Job List Updated Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateJobListFail(error))
    toast.error("Job List Updated Failed", { autoClose: 2000 })
  }
}

function* onDeleteJobList({ payload: data }) {
  try {
    const response = yield call(deleteJobList, data)
    yield put(deleteJobListSuccess(response))
    toast.success("Job List Deleted Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteJobListFail(error))
    toast.error("Job List Deleted Failed", { autoClose: 2000 })
  }
}

function* OnGetApplyJob({ payload: data }) {
  try {
    const response = yield call(getApplyJob, data)
    yield put(getApplyJobSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getApplyJobFail(error))
  }
}

function* OnDeleteApplyJob({ payload: data }) {
  try {
    const response = yield call(deleteApplyJob, data)
    yield put(deleteApplyJobSuccess(response))
    toast.success("Applyed Job Deleted Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteApplyJobFail(error))
    toast.error("Applyed Job Deleted Failed", { autoClose: 2000 })
  }
}

function* jobsSaga() {
  yield takeEvery(GET_JOB_LIST, fetchJobsList)
  yield takeEvery(GET_USER_LIST, fetchUsersList)
  yield takeEvery(ADD_NEW_PORTFOLIO, onAddNewPortfolio)
  yield takeEvery(UPDATE_JOB_LIST, onUpdateJobList)
  yield takeEvery(DELETE_JOB_LIST, onDeleteJobList)
  yield takeEvery(GET_APPLY_JOB, OnGetApplyJob)
  yield takeEvery(DELETE_APPLY_JOB, OnDeleteApplyJob)
  yield takeEvery(ADD_TO_CHATS, addToChatsNow)
  yield takeEvery(PROCESS_QUERY, processQuery)
}

export default jobsSaga
