export const GET_JOB_LIST = "GET_JOBLIST"
export const GET_JOB_LIST_FAIL = "GET_JOB_LIST_FAIL"
export const GET_JOB_LIST_SUCCESS = "GET_JOB_LIST_SUCCESS"

export const GET_USER_LIST = "GET_USERLIST"
export const GET_USER_LIST_FAIL = "GET_USER_LIST_FAIL"
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS"

export const ADD_NEW_PORTFOLIO = "ADD_NEW_PORTFOLIO"
export const ADD_PORTFOLIO_SUCCESS = "ADD_PORTFOLIO_SUCCESS"
export const ADD_PORTFOLIO_FAIL = "ADD_PORTFOLIO_FAIL"

export const UPDATE_JOB_LIST = "UPDATE_JOB_LIST"
export const UPDATE_JOB_LIST_SUCCESS = "UPDATE_JOB_LIST_SUCCESS"
export const UPDATE_JOB_LIST_FAIL = "UPDATE_JOB_LIST_FAIL"

export const DELETE_JOB_LIST = "DELETE_JOB_LIST"
export const DELETE_JOB_LIST_SUCCESS = "DELETE_JOB_LIST_SUCCESS"
export const DELETE_JOB_LIST_FAIL = "DELETE_JOB_LIST_FAIL"
export const GET_APPLY_JOB = "GET_APPLY_JOB"
export const GET_APPLY_JOB_SUCCESS = "GET_APPLY_JOB_SUCCESS"
export const GET_APPLY_JOB_FAIL = "GET_APPLY_JOB_FAIL"
export const DELETE_APPLY_JOB = "DELETE_APPLY_JOB"
export const DELETE_APPLY_JOB_SUCCESS = "DELETE_APPLY_JOB_SUCCESS"
export const DELETE_APPLY_JOB_FAIL = "DELETE_APPLY_JOB_FAIL"

export const SET_PROPERTY_FOR_DETAILS = "SET_PROPERTY_FOR_DETAILS"
export const GET_PROPERTY_FOR_DETAILS = "GET_PROPERTY_FOR_DETAILS"
export const BACK_TO_PROPERTIES = "BACK_TO_PROPERTIES"

export const RESET_CHATS = "RESET_CHATS"
export const RESET_TOAST = "RESET_TOAST"

export const ADD_TO_CHATS = "ADD_TO_CHATS"
export const ADD_TO_CHATS_SUCCESS = "ADD_TO_CHATS_SUCCESS"
export const ADD_TO_CHATS_FAIL = "ADD_TO_CHATS_FAIL"

export const PROCESS_QUERY = "PROCESS_QUERY"
export const PROCESS_QUERY_SUCCESS = "PROCESS_QUERY_SUCCESS"
export const PROCESS_QUERY_FAIL = "PROCESS_QUERY_FAIL"