import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import { jobs } from "../../../common/data";
import moment from 'moment';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getUserList as onGetJobList,
    // addNewJobList as onAddNewJobList,
    updateJobList as onUpdateJobList,
    deleteJobList as onDeleteJobList,
} from "store/actions";

import {
    SerialNo, FirstName, LastName, Role, Level, YesOrNo, Type, MemberSince, Amount, Status
}
    from "./JobListCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner
} from "reactstrap";
import { ToastContainer } from "react-toastify";

function JobList() {

    //meta title
    document.title = "Users List | Skill Mentors | Learn Earn Build";

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [mode, setMode] = useState("");

    const [jobsList, setJobsList] = useState([]);
    const [job, setJob] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            no: (job && job.no) || '',
            firstName: (job && job.firstName) || '',
            lastName: (job && job.lastName) || '',
            role: (job && job.role) || '',
            member: (job && job.member) || '',
        },
        validationSchema: Yup.object({
            no: Yup.string(),
            firstName: Yup.string(),
            lastName: Yup.string(),
            role: Yup.string(),
            member: Yup.string(),
        }),
        onSubmit: (values) => {
            // if (isEdit) {
            //     const updateJobList = {
            //         id: job ? job.id : 0,
            //         jobId: values.jobId,
            //         jobTitle: values.jobTitle,
            //         companyName: values.companyName,
            //         location: values.location,
            //         experience: values.experience,
            //         position: values.position,
            //         type: values.type,
            //         postedDate: "02 June 2021",
            //         lastDate: "25 June 2021",
            //         status: values.status,
            //     };
            //     // update Job
            //     dispatch(onUpdateJobList(updateJobList));
            //     validation.resetForm();
            // } else {
            //     const newJobList = {
            //         id: Math.floor(Math.random() * (30 - 20)) + 20,
            //         jobId: values["jobId"],
            //         jobTitle: values["jobTitle"],
            //         companyName: values["companyName"],
            //         location: values["location"],
            //         experience: values["experience"],
            //         position: values["position"],
            //         type: values["type"],
            //         postedDate: "02 June 2021",
            //         lastDate: "25 June 2021",
            //         status: values["status"],
            //     };
            //     // save new Job
            //     dispatch(onAddNewJobList(newJobList));
            //     validation.resetForm();
            // }
            toggle();
        },
    });

    const dispatch = useDispatch();

    const selectJobsState = (state) => state.JobReducer;
    const JobsJobsProperties = createSelector(selectJobsState, (jobReducer) => ({ jobs: jobReducer.jobs, loading: jobReducer.loading, userData: jobReducer.userData }));

    const { jobs, loading, userData } = useSelector(JobsJobsProperties);

    useEffect(() => {
        dispatch(onGetJobList({ itemsPerPage: 8, page: currentPage, sortKey: 'createdAt', pagination: false }));
    }, [dispatch, currentPage]);

    // useEffect(() => {
    //     setJobsList(jobs);
    // }, [jobs]);

    // useEffect(() => {
    //     if (!isEmpty(jobs) && !!isEdit) {
    //         setJobsList(jobs);
    //         setIsEdit(false);
    //     }
    // }, [jobs]);

    const toggle = () => {
        if (modal) {
            setModal(false);
            setJob(null);
        } else {
            setModal(true);
        }
    };

    const handleUserClick = arg => {
        const user = arg.original;

        if (user) {
            setJob({
                no: arg.index + 1,
                firstName: user.firstName,
                lastName: user.lastName,
                role: user.role,
                member: moment(user.createdAt).format('MMMM Do YYYY'),
            });
        }

        // setIsEdit(true);
        toggle();
    };

    //delete Job
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (job, mode) => {
        setMode(mode);
        setJob(job);
        setDeleteModal(true);
    };

    const handleDeletejob = () => {
        if (job && job.userName) {
            if (mode === "restore") {
                // dispatch(onDeleteJobList(job.userName));
            }
            else {
                // dispatch(onDeleteJobList(job.userName));
            }
            setDeleteModal(false);
        }
    };
    const handleJobClicks = () => {
        setJobsList("");
        setIsEdit(false);
        toggle();
    };

    const columns = useMemo(
        () => [
            {
                Header: 'No',
                accessor: '',
                filterable: false,
                Cell: (cellProps) => {
                    return <SerialNo {...cellProps} />;
                }
            },
            {
                Header: 'First Name',
                accessor: 'firstName',
                filterable: true,
                Cell: (cellProps) => {
                    return <FirstName {...cellProps} />;
                }
            },
            {
                Header: 'Last Name',
                accessor: 'lastName',
                filterable: true,
                Cell: (cellProps) => {
                    return <LastName {...cellProps} />;
                }
            },
            {
                Header: 'Role',
                accessor: 'role',
                filterable: true,
                Cell: (cellProps) => {
                    return <Role {...cellProps} />;
                }
            },
            {
                Header: 'Member Since',
                accessor: 'createdAt',
                Cell: (cellProps) => {
                    return <MemberSince {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'isActive',
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link to="/job-details" className="btn btn-sm btn-soft-info" id={`viewtooltip-${cellProps.row.original.id}`}>
                                    <i className="mdi mdi-eye-outline" />
                                </Link>
                            </li>
                            <UncontrolledTooltip placement="top" target={`viewtooltip-${cellProps.row.original.id}`}>
                                View
                            </UncontrolledTooltip> */}

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-soft-warning"
                                    onClick={() => {
                                        const userData = cellProps.row;
                                        handleUserClick(userData);
                                    }}
                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-eye fs-5" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Detail
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            {cellProps.row.values.isActive ? <li>
                                <Link
                                    to="#"
                                    className="btn btn-soft-danger"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        onClickDelete(jobData, "archive");
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete fs-5" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Archive
                                    </UncontrolledTooltip>
                                </Link>
                            </li> : <li>
                                <Link
                                    to="#"
                                    className="btn btn-soft-success"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        onClickDelete(jobData, "restore");
                                    }}
                                    id={`restoretooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-off fs-5" />
                                    <UncontrolledTooltip placement="top" target={`restoretooltip-${cellProps.row.original.id}`}>
                                        Restore
                                    </UncontrolledTooltip>
                                </Link>
                            </li> }
                        </ul>
                    );
                }
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                type={"user"}
                onDeleteClick={handleDeletejob}
                onCloseClick={() => setDeleteModal(false)}
                mode={mode}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Skill Mentors" breadcrumbItem="Users List" />
                    {
                        loading ? <Spinner type="grow" className="ms-n3" style={{ position: 'relative', left: '50%' }} color="secondary" />
                            :
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        {userData && userData.length ? <CardBody>
                                            <TableContainer
                                                key={'usersTable'}
                                                columns={columns}
                                                data={userData}
                                                totalItems={userData.totalItems || 0}
                                                isGlobalFilter={true}
                                                isAddOptions={false}
                                                handleJobClicks={handleJobClicks}
                                                isJobListGlobalFilter={true}
                                                isPagination={true}
                                                iscustomPageSizeOptions={true}
                                                isShowingPageLength={true}
                                                customPageSize={8}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                tableClass="table-bordered align-middle nowrap mt-2"
                                                paginationDiv="col-sm-12 col-md-7"
                                                pagination="pagination justify-content-end pagination-rounded"
                                            />
                                        </CardBody> : null}
                                    </Card>
                                </Col>
                            </Row>
                    }
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "User Detail" : "User Detail"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label className="form-label">No</Label>
                                            <Input
                                                name="no"
                                                type="text"
                                                validate={{ required: { value: true }, }}
                                                disabled={true}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.no || ""}
                                                invalid={validation.touched.no && validation.errors.no ? true : false}
                                            />
                                            {validation.touched.no && validation.errors.no ? (
                                                <FormFeedback type="invalid">{validation.errors.no}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">FirstName</Label>
                                            <Input
                                                name="firstName"
                                                type="text"
                                                validate={{ required: { value: true }, }}
                                                disabled={true}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.firstName || ""}
                                                invalid={validation.touched.firstName && validation.errors.firstName ? true : false}
                                            />
                                            {validation.touched.firstName && validation.errors.firstName ? (
                                                <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">LastName</Label>
                                            <Input
                                                name="lastName"
                                                type="text"
                                                disabled={true}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.lastName || ""}
                                                invalid={validation.touched.lastName && validation.errors.lastName ? true : false}
                                            />
                                            {validation.touched.lastName && validation.errors.lastName ? (
                                                <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Role</Label>
                                            <Input
                                                name="role"
                                                type="text"
                                                disabled={true}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.role || ""}
                                                invalid={validation.touched.role && validation.errors.role ? true : false}
                                            />
                                            {validation.touched.role && validation.errors.role ? (
                                                <FormFeedback type="invalid">{validation.errors.role}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Member Since</Label>
                                            <Input
                                                name="member"
                                                type="text"
                                                disabled={true}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.member || ""}
                                                invalid={validation.touched.member && validation.errors.member ? true : false}
                                            >
                                            </Input>
                                            {validation.touched.member && validation.errors.member ? (
                                                <FormFeedback member="invalid">{validation.errors.member}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button type="button" className="btn btn-success save-user" onClick={() => toggle()}>Close</button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}


export default JobList;