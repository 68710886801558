import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

//import images
import avatar from "../../../assets/images/users/avatar-0-generated.png"

import withRouter from "components/Common/withRouter"
import AboutUs from "./AboutUs"
import Sidebar from "./Sidebar"

import { getApplyJob } from "store/actions"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const CandidateOverview = props => {
  document.title = `${props?.router?.params?.userName} - Public Profile | Skill Mentors | Learn Earn Build`
  const params = props.router.params

  const [showMessageArea, setShowMessageArea] = useState(false)

  const dispatch = useDispatch()

  const selectContactsState = state => state.contacts
  const ContactsProperties = createSelector(selectContactsState, Contacts => ({
    model: Contacts.user,
  }))

  const { model } = useSelector(ContactsProperties)

  const selectUserState = state => state.JobReducer
  const UserProperties = createSelector(selectUserState, jobReducer => ({
    user: jobReducer.jobApply,
    loading: jobReducer.loading,
  }))

  const { user, loading } = useSelector(UserProperties)

  useEffect(() => {
    if (params && params.userName) {
      dispatch(getApplyJob({ userName: params.userName, modelId: model?._id }))
    }
  }, [params.userName])

  useEffect(() => {}, [user])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card className="mx-n4 mt-n4 bg-dark-subtle">
                <CardBody>
                  <div className="text-center mb-4">
                    <img
                      src={user?.image || avatar}
                      alt=""
                      className="avatar-md rounded-circle mx-auto d-block"
                    />
                    <h5 className="mt-3 mb-1">
                      {user?.firstName} {user?.lastName}
                    </h5>
                    <p className="text-muted mb-3">{user?.description}</p>
                  </div>
                  {user?.model?._id && (
                    <div className="text-center">
                      <div>
                        {!showMessageArea ? (
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => setShowMessageArea(true)}
                          >
                            Send Message{" "}
                            <i className="bx bx-chat align-baseline ms-1"></i>
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={() => setShowMessageArea(false)}
                          >
                            Exit Chat{" "}
                            <i className="bx bx-exit align-baseline ms-1"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Sidebar user={user} showMessageArea={showMessageArea} />
            <AboutUs user={user} showMessageArea={showMessageArea} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CandidateOverview)
