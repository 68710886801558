import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { map } from "lodash";
import moment from "moment";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Input,
  Form,
  Spinner
} from "reactstrap";

// TableContainer

import { Pdate, Ddate, Name, Idno, Budget } from "./CryptoCol";

import TableContainer from "../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import mini card widgets
import MiniCards from "./mini-card";

//Import Images
import profile1 from "assets/images/profile-img.png";
import avatar1 from "assets/images/users/avatar-0-generated.png"

// import charts
import ApexRevenue from "../ApexRevenue";
import { getUserProfile } from "store/actions";

const ContactsProfile = props => {

  //meta title
  document.title = "Profile | Skill Mentors | Learn Earn Build";

  const { userProfile, onGetUserProfile, loading } = props;
  // eslint-disable-next-line no-unused-vars
  const [miniCards, setMiniCards] = useState([
    { title: "Completed Projects", iconClass: "bx-check-circle", text: "125" },
    { title: "Pending Projects", iconClass: "bx-hourglass", text: "12" },
    { title: "Total Revenue", iconClass: "bx-package", text: "$36,524" },
  ]);

  function getPersonalInformation() {
    let info = "";

    if (userProfile.firstName) {
      info = info + userProfile.firstName + ' ';
    }
    if (userProfile.lastName) {
      info = info + userProfile.lastName + ' is ';
    }

    if (userProfile.subscription && userProfile.subscription.billPaid) {
      info = info + 'a paying ';
    }
    else {
      info = info + 'a on trial ';
    }

    if (userProfile.role) {
      info = info + userProfile.role + ' and is enjoying ';
    }

    if (userProfile.subscription && userProfile.subscription.description) {
      info = info + userProfile.subscription.description.toLowerCase();
    }

    return info;
  }

  useEffect(() => {
    onGetUserProfile();
  }, [onGetUserProfile]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Idno {...cellProps} />;
        },
      },
      {
        Header: "Project",
        accessor: "name",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Pdate {...cellProps} />;
        },
      },
      {
        Header: "Deadline",
        accessor: "deadline",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Ddate {...cellProps} />;
        },
      },
      {
        Header: "Budget",
        accessor: "budget",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Budget {...cellProps} />;
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Skill Mentors" breadcrumbItem="Profile" />

          {
            loading ? <Spinner type="grow" className="ms-n3" style={{ position: 'relative', left: '50%' }} color="secondary" />
              :
              <>
                <Row>
                  <Col xl="4">
                    <Card className="overflow-hidden">
                      <div className="bg-warning-subtle">
                        <Row>
                          <Col xs="7">
                            <div className="text-dark p-2">
                              <h5 className="text-dark">Welcome {userProfile.firstName} !</h5>
                            </div>
                          </Col>
                          <Col xs="5" className="align-self-end">
                            <img src={profile1} alt="" className="img-fluid" />
                          </Col>
                        </Row>
                      </div>
                      <CardBody className="pt-0">
                        <Row>
                          <Col sm="6">
                            <div className="avatar-md profile-user-wid mb-4">
                              <img
                                src={userProfile.image || avatar1}
                                alt={userProfile.userName}
                                className="img-thumbnail rounded-circle"
                              />
                            </div>
                            <h5 className="font-size-15 text-truncate">
                              {userProfile.userName}
                            </h5>
                            <p className="text-muted mb-0 text-truncate">
                              {userProfile.role}
                            </p>
                          </Col>

                          <Col sm={6}>
                            <div className="pt-4">
                              <Row>
                                <Col xs="12">
                                  <h5 className="font-size-14">
                                    {moment(userProfile.createdAt).format('MMMM Do YYYY')}
                                  </h5>
                                  <p className="text-muted mb-0">Member Since</p>
                                </Col>
                                {/* <Col xs="6">
                                  <h5 className="font-size-15">
                                    ${userProfile.revenue}
                                  </h5>
                                  <p className="text-muted mb-0">Revenue</p>
                                </Col> */}
                              </Row>
                              <div className="mt-4">
                                <Link to="/update-profile" className="btn btn-secondary  btn-sm">
                                  Update Profile{" "}
                                  <i className="mdi mdi-arrow-right ms-1" />
                                </Link>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Personal Information</CardTitle>
                        <p className="text-muted mb-4">
                          {/* {getPersonalInformation()} */}
                        </p>
                        <div className="table-responsive">
                          <Table className="table-nowrap mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">Full Name :</th>
                                <td>{userProfile.firstName} {userProfile.lastName}</td>
                              </tr>
                              <tr>
                                <th scope="row">E-mail :</th>
                                <td>{userProfile.email}</td>
                              </tr>
                              {userProfile.subscription ? <tr>
                                <th scope="row">Subscription :</th>
                                <td>{userProfile.subscription.level} - {userProfile.subscription.amount}</td>
                                <td></td>
                              </tr> : null}
                              {userProfile.subscription ? <tr>
                                <th scope="row">Last Updated :</th>
                                <td>{moment(userProfile.subscription.lastUpdated).format('MM DD YYYY')}</td>
                                <td></td>
                              </tr> : null}
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col xl="8">
                    {/* <Row>
                      <Col lg={12}>
                        <Card className="job-filter">
                          <CardBody>
                            <Form>
                              <Row className="g-3">
                                <Col xxl={2} lg={6}>
                                  <div className="position-relative">
                                    <button type="button" className="btn btn-secondary h-100 w-100">Add Portfolio Property</button>
                                  </div>
                                </Col>

                                <Col xxl={12} lg={12}>
                                  <div className="position-relative h-100 hstack gap-3">
                                    <Link to="/portfolios" className="btn btn-secondary h-100 w-100">View Portfolios Grid</Link>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row> */}
                    {/* <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Revenue</CardTitle>
                        <div id="revenue-chart">
                          <ApexRevenue dataColors='["--bs-primary"]' />
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">My Projects</CardTitle>

                        <TableContainer
                          columns={columns}
                          data={userProfile.projects || []}
                          isGlobalFilter={false}
                          customPageSize={7}
                          customPageSizeOptions={true}
                        />
                      </CardBody>
                    </Card> */}
                  </Col>
                </Row>
              </>
          }
        </Container>
      </div>
    </React.Fragment>
  );
};

ContactsProfile.propTypes = {
  userProfile: PropTypes.any,
  loading: PropTypes.bool,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
  loading: contacts.loading,
});

const mapDispatchToProps = dispatch => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsProfile));
