import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_USERS,
  GET_USER_PROFILE,
  ADD_NEW_USER,
  DELETE_USER,
  UPDATE_USER,
  UPLOAD_FILES,
  GET_COURSES,
  GET_COURSES_SUCCESS,
  GET_COURSES_FAIL,
  UPDATE_CLICK_COUNT,
  DELETE_FILES
} from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  uploadFilesFail,
  uploadFilesSuccess,
  getCoursesSuccess,
  getCoursesFail,
  deleteFileSuccess,
  deleteFileFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getUsers,
  getUserProfile,
  addNewUser,
  updateUser,
  deleteUser,
  uploadFilesOnServer,
  getCourses,
  patchUpdateClickCount,
  deleteFileOnServer
} from "../../helpers/fakebackend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchCourses() {
  try {
    const response = yield call(getCourses)
    yield put(getCoursesSuccess(response))
  } catch (error) {
    yield put(getCoursesFail(error))
  }
}

function* fetchUsers() {
  try {
    const response = yield call(getUsers)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* updateClickCount({ payload: user }) {
  try {
    yield call(patchUpdateClickCount, user)
  } catch (error) {
  }
}

function* fetchUserProfile() {
  try {
    const response = yield call(getUserProfile)
    yield put(getUserProfileSuccess(response))
  } catch (error) {
    yield put(getUserProfileFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const response = yield call(updateUser, user)
    yield put(updateUserSuccess({ response: response, user: { ...user, trainingInprogress: true } }))
    toast.success("Contact Updated Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateUserFail(error))
    toast.error("Contact Updated Failed", { autoClose: 2000 })
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    yield call(deleteUser, user)
    yield put(deleteUserSuccess(user))
    toast.success("Contact Deleted Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteUserFail(error))
    toast.error("Contact Deleted Failed", { autoClose: 2000 })
  }
}

function* onAddNewUser({ payload: user }) {
  try {
    const response = yield call(addNewUser, user)
    yield put(addUserSuccess({ ...response, _id: user._id }))
    toast.success("Model added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addUserFail(error))
    toast.error("Model could not be added", { autoClose: 2000 })
  }
}

function* uploadFiles({ payload: { files } }) {
  try {
    const response = yield call(uploadFilesOnServer, files)
    yield put(uploadFilesSuccess(response))
    toast.success("File added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(uploadFilesFail(error))
    toast.error("File could not be added", { autoClose: 2000 })
  }
}

function* deleteFiles({ payload: { file } }) {
  try {
    const response = yield call(deleteFileOnServer, file)
    yield put(deleteFileSuccess(response))
    toast.success("File deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteFileFail(error))
    toast.error("File could not be deleted", { autoClose: 2000 })
  }
}

function* contactsSaga() {
  yield takeEvery(GET_COURSES, fetchCourses)
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(UPLOAD_FILES, uploadFiles)
  yield takeEvery(DELETE_FILES, deleteFiles)
  yield takeEvery(UPDATE_CLICK_COUNT, updateClickCount)
}

export default contactsSaga
