import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-0-generated.png"
import profileImg from "../../assets/images/profile-img.png"

const WelcomeComp = props => {
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-warning-subtle">
          <Row>
            <Col xs="7">
              <div className="text-dark p-3">
                <h5 className="text-dark">Welcome Back !</h5>
                <p>Skill Mentors Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="6">
              <div className="avatar-md profile-user-wid mb-2">
                <img
                  src={props?.user?.image || avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">{props.user.userName}</h5>
              <p className="text-muted mb-0 text-truncate">{props.user.role}</p>
            </Col>

            <Col sm="6">
              <div className="pt-4">
                <Row>
                  <Col xs="10">
                    {/* <p className="text-muted mb-0">Portfolio Count</p> */}
                  </Col>
                  <Col xs="2">
                    {/* <h5 className="font-size-15">{props.user.portfolioCount || 0}</h5> */}
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link to="/update-profile" className="btn btn-secondary btn-sm">
                    Update Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
