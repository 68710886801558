import React, { useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Spinner } from "reactstrap";
import Select from "react-select";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, registerError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { Link, useNavigate } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/fulllogo_nobuffer-removebg.png";

const Register = props => {

  //meta title
  document.title = "Register | Skill Mentors | Learn Earn Build";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      let data = {
        email: values.email,
        userName: values.username,
        password: values.password,
        nickName: values.username,
        roleTitle: 'Member',
      };

      dispatch(registerUser(data));
    }
  });

  const subscriptionOptions = [
    {
      label: "Subscription Levels",
      options: [
        { label: "Silver - $25", value: "Silver" },
        { label: "Gold - $40", value: "Gold" },
        { label: "Platinum - $50", value: "Platinum" }
      ]
    }
  ];

  const defaultValue = (options, value) => {
    return options ? options.find(option => option.value === value) : '';
  };

  const selectAccountState = (state) => state.Account;
  const AccountProperties = createSelector(
    selectAccountState,
    (account) => ({
      user: account.user,
      registrationError: account.registrationError,
      success: account.success,
      loading: account.loading,
    })
  );

  const {
    user,
    registrationError, success,
    loading
  } = useSelector(AccountProperties);

  useEffect(() => {
    dispatch(registerError(""));
  }, []);

  useEffect(() => {
    success && setTimeout(() => navigate("/login"), 2000)
    registerError("");
  }, [success]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-warning-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-dark p-4">
                        <h5 className="text-dark">Register</h5>
                        <p>Get your <Link to="/" className="fw-medium text-dark">
                            {" "}
                            Skill Mentors{" "}
                          </Link>{" "} account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="60"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {success ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label for="email" className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          autoComplete="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label for="username" className="form-label">Username</Label>
                        <Input
                          id="username"
                          name="username"
                          type="text"
                          autoComplete="username"
                          placeholder="Enter username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label for="password" className="form-label">Password</Label>
                        <Input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      {/* <div className="mb-3">
                        <Label className="form-label">Subscription</Label>
                        <Select
                          className="select2-selection"
                          name="subscription"
                          type="subscription"
                          placeholder="Select a subscription Level"
                          value={defaultValue(subscriptionOptions, validation.values.subscription)}
                          onChange={e => validation.setFieldValue('subscription', e.value)}
                          options={subscriptionOptions}
                          invalid={
                            validation.touched.subscription && validation.errors.subscription ? true : false
                          }
                        />
                        {validation.touched.subscription && validation.errors.subscription ? (
                          <FormFeedback type="invalid">{validation.errors.subscription}</FormFeedback>
                        ) : null}
                        <p style={{fontSize: "0.7rem"}}>One week of free trial period, you will be charged after the trial period.</p>
                      </div> */}

                      <div className="mt-4 d-grid">
                        <button className="btn btn-secondary btn-block " type="submit">
                          Register
                        </button>
                      </div>

                      {loading ? <div className="mt-2 d-grid"> <Spinner type="grow" className="ms-n3" style={{ position: 'relative', left: '50%' }} color="secondary" /></div> : null}

                      {/* <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Skill Mentors{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-warning">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skill Mentors
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
