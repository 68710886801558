import {
  GET_PORTFOLIO,
  GET_PORTFOLIO_FAIL,
  GET_PORTFOLIO_SUCCESS,
  ADD_TO_PORTFOLIO,
  ADD_TO_PORTFOLIO_SUCCESS,
  ADD_TO_PORTFOLIO_FAIL,
  RESET_TOAST
} from "./actionTypes";

const INIT_STATE = {
  portfolioData: {},
  property: {},
  error: {},
  page: 1,
  setPage: false,
  loading: false,
  showToast: false,
}

const blogReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PORTFOLIO:
      return {
        ...state,
        loading: true
      };

    case GET_PORTFOLIO_SUCCESS:
      return {
        ...state,
        portfolioData: action.payload,
        setPage: false,
        loading: false
      };

    case GET_PORTFOLIO_FAIL:
      return {
        ...state,
        error: action.payload,
        setPage: false,
        loading: false
      };

    case ADD_TO_PORTFOLIO:
      return {
        ...state,
        showToast: false,
        loading: true
      };

    case ADD_TO_PORTFOLIO_SUCCESS:
      return {
        ...state,
        showToast: true,
        loading: false
      };

    case ADD_TO_PORTFOLIO_FAIL:
      return {
        ...state,
        error: action.payload,
        showToast: true,
        loading: false
      };

    case RESET_TOAST:
      return {
        ...state,
        error: null,
        showToast: false,
        loading: false
      };

    default:
      return state
  }
}

export default blogReducer;