import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here

//apply base url for axios
const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] =
  localStorage.getItem("Authorization")

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, addAuth, addCredentials, dummyCall) {
  let headers = {
    "Access-Control-Allow-Origin": process.env.REACT_APP_FRONTEND,
    "Content-Type": "application/json",
  }
  if (addAuth) {
    headers = {
      ...headers,
      Authorization: localStorage.getItem("Authorization"),
    }
  }

  let config = { headers: headers }
  if (addCredentials) {
    config.withCredentials = true
  }

  return await axiosApi
    .get(url, config)
    .then(response => {
      if (dummyCall) return response.data
      else if (
        response.data.responseCode >= 200 &&
        response.data.responseCode <= 399
      )
        return response.data.data ? response.data.data : response.data
      throw response.data
    })
    .catch(err => {
      let message = err.message
      if (err.responseCode && err.data && err.data.length) {
        message = err.data.map(d => d.message).join(", ")
      }
      throw message
    })
}

export async function post(url, data, addAuth, addCredentials, isFile) {
  let headers = {
    "Access-Control-Allow-Origin": process.env.REACT_APP_FRONTEND,
  }
  if (!isFile) {
    headers = { ...headers, "Content-Type": "application/json" }
  } else {
    headers = {
      ...headers,
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
    }
  }

  if (addAuth) {
    headers = {
      ...headers,
      Authorization: localStorage.getItem("Authorization"),
    }
  }

  let config = { headers: headers }
  if (addCredentials) {
    config.withCredentials = true
  }

  return axiosApi
    .post(url, data, config)
    .then(response => {
      if (
        response.data.responseCode >= 200 &&
        response.data.responseCode <= 399
      )
        return response.data.data ? response.data.data : response.data
      throw response.data
    })
    .catch(err => {
      if (
        url.includes("logout") &&
        err.response &&
        err.response.data &&
        err.response.data === "Unauthorized"
      ) {
        localStorage.removeItem("authUser")
        localStorage.removeItem("Authorization")
        window.location.href = process.env.REACT_APP_FRONTEND + "/login"
      }

      let message = err.message
      if (err.responseCode && err.data && err.data.length) {
        message = err.data.map(d => d.message).join(", ")
      }
      throw message
    })
}

export async function put(url, data, addAuth, addCredentials) {
  let headers = {
    "Access-Control-Allow-Origin": process.env.REACT_APP_FRONTEND,
    "Content-Type": "application/json",
  }
  if (addAuth) {
    headers = {
      ...headers,
      Authorization: localStorage.getItem("Authorization"),
    }
  }

  let config = { headers: headers }
  if (addCredentials) {
    config.withCredentials = true
  }

  return axiosApi
    .put(url, { ...data }, config)
    .then(response => {
      if (
        response.data.responseCode >= 200 &&
        response.data.responseCode <= 399
      )
        return response.data.data ? response.data.data : response.data
      throw response.data
    })
    .catch(err => {
      let message = err.message
      if (err.responseCode && err.data && err.data.length) {
        message = err.data.map(d => d.message).join(", ")
      }
      throw message
    })
}

export async function patch(url, data, addAuth, addCredentials) {
  let headers = {
    "Access-Control-Allow-Origin": process.env.REACT_APP_FRONTEND,
    "Content-Type": "application/json",
  }
  if (addAuth) {
    headers = {
      ...headers,
      Authorization: localStorage.getItem("Authorization"),
    }
  }

  let config = { headers: headers }
  if (addCredentials) {
    config.withCredentials = true
  }

  return axiosApi
    .patch(url, { ...data }, config)
    .then(response => {
      if (
        response.data.responseCode >= 200 &&
        response.data.responseCode <= 399
      )
        return response.data.data ? response.data.data : response.data
      throw response.data
    })
    .catch(err => {
      let message = err.message
      if (err.responseCode && err.data && err.data.length) {
        message = err.data.map(d => d.message).join(", ")
      }
      throw message
    })
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
