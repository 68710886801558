import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE, EDIT_PROFILE_IMAGE } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeProfile,
  postJwtProfile,
  postProfileImage
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend()

function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.editProfileAPI,
        user.username,
        user.idx
      );
      yield put(profileSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtProfile, user);
      if (response.status) {
        let obj = JSON.parse(localStorage.getItem("authUser"));
        obj.firstName = user.firstName;
        obj.lastName = user.lastName;
        obj.phoneNo = user.phoneNo;
        obj.website = user.website;
        obj.whatsapp = user.whatsapp;
        obj.linkedIn = user.linkedIn;
        obj.facebook = user.facebook;
        obj.instagram = user.instagram;
        obj.description = user.description;
        localStorage.setItem('authUser', JSON.stringify(obj));
        yield put(profileSuccess(response.message));
      }
      else {
        yield put(profileError(response.message));
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeProfile, {
        username: user.username,
        idx: user.idx,
      });
      yield put(profileSuccess(response));
    }
  } catch (error) {
    yield put(profileError(error));
  }
}
function* editProfileImage({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postProfileImage, user)
      if (response.status) {
        let obj = JSON.parse(localStorage.getItem("authUser"));
        obj.image = response.link;
        localStorage.setItem('authUser', JSON.stringify(obj));
        yield put(profileSuccess(response.message));
      }
      else {
        yield put(profileError(response.message));
      }
    }
  } catch (error) {
    yield put(profileError(error));
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(EDIT_PROFILE_IMAGE, editProfileImage)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
