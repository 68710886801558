import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  RESET_USER,
  UPLOAD_FILES,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_FAIL,
  DELETE_FILES,
  DELETE_FILES_FAIL,
  DELETE_FILES_SUCCESS,
  GET_COURSES,
  GET_COURSES_SUCCESS,
  GET_COURSES_FAIL,
  RESET_MESSAGES,
  UPDATE_CLICK_COUNT
} from "./actionTypes"

export const resetUser = user => ({
  type: RESET_USER,
  payload: user,
})

export const updateClickCount = user => ({
  type: UPDATE_CLICK_COUNT,
  payload: user,
})

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getCourses = () => ({
  type: GET_COURSES,
})

export const getCoursesSuccess = courses => ({
  type: GET_COURSES_SUCCESS,
  payload: courses,
})

export const getCoursesFail = error => ({
  type: GET_COURSES_FAIL,
  payload: error,
})

export const deleteFileOnServer = file => {
  return {
    type: DELETE_FILES,
    payload: { file },
  }
}

export const deleteFileSuccess = msg => {
  return {
    type: DELETE_FILES_SUCCESS,
    payload: msg,
  }
}

export const deleteFileFail = error => {
  return {
    type: DELETE_FILES_FAIL,
    payload: error,
  }
}

export const uploadFiles = files => {
  return {
    type: UPLOAD_FILES,
    payload: { files },
  }
}

export const uploadFilesSuccess = msg => {
  return {
    type: UPLOAD_FILES_SUCCESS,
    payload: msg,
  }
}

export const uploadFilesFail = error => {
  return {
    type: UPLOAD_FILES_FAIL,
    payload: error,
  }
}

export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user,
})

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const getUserProfile = () => {
  return {
    type: GET_USER_PROFILE,
    payload: {},
  }
}

export const getUserProfileSuccess = userProfile => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
})

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = data => ({
  type: UPDATE_USER_SUCCESS,
  payload: data,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const resetMessages = reset => ({
  type: RESET_MESSAGES,
  payload: reset,
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})
