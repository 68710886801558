import React, { useState, useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop"
// https://github.com/OneLightWebDev/react-image-cropper
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Table,
  Spinner,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"

import QRCode from "react-qr-code"
import * as htmlToImage from "html-to-image"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import withRouter from "components/Common/withRouter"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-0-generated.png"
// actions
import {
  editProfile,
  editProfileImage,
  resetProfileFlag,
} from "../../store/actions"

const ASPECT_RATIO = 1
const MIN_DIMENSION = 150

const UserProfile = () => {
  //meta title
  document.title = "Profile | Skill Mentors | Learn Earn Build"

  const dispatch = useDispatch()
  const cardRef = useRef(null)
  const uploadedImage = useRef(null)
  const imageUploader = useRef(null)

  const [link, setLink] = useState(avatar)
  const [email, setEmail] = useState("")
  const [userName, setUserName] = useState("")
  const [fullName, setFullName] = useState("")
  const [phoneNo, setPhoneNo] = useState("")
  const [role, setRole] = useState("")
  const [website, setWebsite] = useState("")
  const [whatsapp, setWhatsapp] = useState("")
  const [linkedIn, setLinkedIn] = useState("")
  const [facebook, setFacebook] = useState("")
  const [instagram, setInstagram] = useState("")
  const [description, setDescription] = useState("")
  const [showCard, setShowCard] = useState(false)
  const [idx, setIdx] = useState(0)
  const [image, setImage] = useState(null)
  const [imgUploaded, setImgUploaded] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [crop, setCrop] = useState()

  const selectProfileState = state => state.Profile
  const ProfileProperties = createSelector(selectProfileState, profile => ({
    error: profile.error,
    success: profile.success,
    loading: profile.loading,
  }))
  const { error, success, loading } = useSelector(ProfileProperties)

  const setValues = async values => {
    values.image ? setImgUploaded(true) : null

    await Promise.all([
      setFullName(values.fullName),
      setPhoneNo(values.phoneNo),
      setWebsite(values.website),
      setWhatsapp(values.website),
      setLinkedIn(values.website),
      setFacebook(values.website),
      setInstagram(values.website),
      setDescription(values.description),
      values.image ? setLink(values.image) : null,
    ])
  }

  const handleImageUpload = e => {
    const [file] = e.target.files
    if (file) {
      file.calculated = file?.size / 1024
      if (file.calculated > 1000) {
        file.calculated = file?.calculated / 1024
        file.stringSize = (file?.calculated).toFixed(2) + " MB"
      } else {
        file.stringSize = (file?.calculated).toFixed(2) + " KB"
      }

      // const formData = new FormData()

      // formData.append("file", file)
      // dispatch(editProfileImage(formData))

      const reader = new FileReader()
      const { current } = uploadedImage
      current.file = file
      reader.onload = e => {
        current.src = e.target.result
      }
      reader.readAsDataURL(file)

      setImage(file)
      // setImgUploaded(true);
    }
  }

  const onImageLoad = e => {
    const { width, height } = e.currentTarget
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    )
    const centeredCrop = centerCrop(crop, width, height)
    setCrop(centeredCrop)
  }

  const toggle = () => {
    if (modalOpen) {
      setModalOpen(false)
    } else {
      setModalOpen(true)
    }
  }

  const downloadCard = e => {
    e.preventDefault()

    htmlToImage
      .toPng(cardRef.current)
      .then(function (dataUrl) {
        const link = document.createElement("a")
        link.href = dataUrl
        link.crossorigin = "anonymous"
        link.download = `${userName}-card.png`
        link.click()
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setUserName(obj.displayName)
        setEmail(obj.email)
        setIdx(obj.uid)
      } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
        setUserName(obj.displayName)
        setEmail(obj.email)
        setIdx(obj.uid)
      } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
        setUserName(obj.userName)
        setEmail(obj.email)
        setFullName(obj?.firstName?.concat(" ", obj?.lastName) || "")
        setRole(obj.role)
        setIdx(obj.id)
        setPhoneNo(obj.phoneNo)
        setWebsite(obj.website)
        setWhatsapp(obj.whatsapp)
        setLinkedIn(obj.linkedIn)
        setFacebook(obj.facebook)
        setInstagram(obj.instagram)
        setDescription(obj.description)
        if (obj.image) {
          setLink(obj.image)
          setImgUploaded(true)
        }
      }
      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [dispatch, success])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      fullName: fullName || "",
      phoneNo: phoneNo || "",
      website: website || "",
      whatsapp: whatsapp || "",
      linkedIn: linkedIn || "",
      facebook: facebook || "",
      instagram: instagram || "",
      description: description || "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Please Enter Your First Name"),
      phoneNo: Yup.string().required("Please Enter Your Phone Number"),
      website: Yup.string(),
      whatsapp: Yup.string(),
      linkedIn: Yup.string(),
      facebook: Yup.string(),
      instagram: Yup.string(),
    }),
    onSubmit: async values => {
      await setValues(values)
      let [firstName, ...lastName] = values.fullName.split(" ")
      dispatch(
        editProfile({
          userName: userName,
          firstName: firstName,
          lastName: lastName.join(" "),
          phoneNo: values.phoneNo,
          website: values.website,
          whatsapp: values.whatsapp,
          linkedIn: values.linkedIn,
          facebook: values.facebook,
          instagram: values.instagram,
          description: values.description,
        })
      )
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="mb-2">
          {/* Render Breadcrumb */}
          <Breadcrumb title="Skill Mentors" breadcrumbItem="Update Profile" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div
                      className="ms-2 me-3"
                      onClick={() => setModalOpen(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={link}
                        className="avatar-lg rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted text-truncate">
                        <Row>
                          <Col md={6}>
                            <p className="mb-1">
                              <strong>Username:</strong> {userName}
                            </p>
                            <p className="mb-1">
                              <strong>Email:</strong> {email}
                            </p>
                          </Col>
                          <Col md={6}>
                            <p className="mb-1">
                              <strong>Id #:</strong> {idx}
                            </p>
                            <p className="mb-1">
                              <strong>Role:</strong> {role}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={modalOpen} toggle={toggle} scrollable={false}>
            <ModalHeader toggle={toggle} tag="h6">
              {`Edit Profile Image`}
            </ModalHeader>
            <ModalBody
              className="justify-center px-2 py-12 text-center"
              style={{ height: "35rem" }}
            >
              <div className="block mb-3 w-fit">
                <Button
                  color="light"
                  className="btn-rounded"
                  onClick={() => imageUploader.current.click()}
                >
                  Choose Image
                </Button>
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*"
                  multiple={false}
                  ref={imageUploader}
                  onChange={handleImageUpload}
                />
              </div>
              <div className="block w-fit">
                <span>
                  {" "}
                  {image
                    ? image?.name + " (" + image?.stringSize + ")"
                    : "No Image Choosen"}{" "}
                </span>
              </div>
              <div className="block w-fit border-top">
                {/* <ReactCrop
                  crop={crop}
                  onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
                  circularCrop
                  keepSelection
                  aspect={ASPECT_RATIO}
                  minWidth={MIN_DIMENSION}
                > */}
                <img
                  ref={uploadedImage}
                  style={{ maxHeight: "28rem" }}
                  onLoad={onImageLoad}
                />
                {/* </ReactCrop> */}
              </div>
            </ModalBody>
          </Modal>

          {error && error ? <Alert color="danger">{error}</Alert> : null}
          {success ? <Alert color="success">{success}</Alert> : null}
          {loading ? (
            <Spinner
              type="grow"
              className="ms-n3"
              style={{ position: "relative", left: "50%" }}
              color="secondary"
            />
          ) : (
            <Card
              style={{
                maxWidth: showCard ? "650px" : "",
                margin: showCard ? "auto" : "",
              }}
            >
              {showCard ? (
                <CardHeader>
                  <Button
                    type="button"
                    className="me-3"
                    color="success"
                    onClick={e => downloadCard(e)}
                  >
                    <i className="bx bx-download" title="Download Card"></i>
                  </Button>
                  <Button
                    type="button"
                    className="me-3"
                    color="warning"
                    onClick={e => {
                      e.preventDefault()
                      setShowCard(false)
                      return false
                    }}
                  >
                    <i className="bx bx-edit-alt" title="Update Profile"></i>
                  </Button>
                </CardHeader>
              ) : null}
              <CardBody className={showCard ? "text-center" : ""}>
                {!showCard ? (
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="form-group">
                      <Label className="form-label" for="fullName">
                        Full Name
                      </Label>
                      <Input
                        id="fullName"
                        name="fullName"
                        autoComplete="off"
                        className="form-control"
                        placeholder="Enter Full Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.fullName || ""}
                        invalid={
                          validation.touched.fullName &&
                          validation.errors.fullName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.fullName &&
                      validation.errors.fullName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.fullName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <br />
                    <Row>
                      <Col md={6}>
                        <div className="form-group">
                          <Label className="form-label" for="phoneNo">
                            Phone #
                          </Label>
                          <Input
                            id="phoneNo"
                            name="phoneNo"
                            autoComplete="off"
                            className="form-control"
                            placeholder="Enter Phone Number"
                            type="tel"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phoneNo || ""}
                            invalid={
                              validation.touched.phoneNo &&
                              validation.errors.phoneNo
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phoneNo &&
                          validation.errors.phoneNo ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phoneNo}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <Label className="form-label" for="whatsapp">
                            Whatsapp URL
                          </Label>
                          <Input
                            id="whatsapp"
                            name="whatsapp"
                            autoComplete="off"
                            className="form-control"
                            placeholder="Enter Whatsapp URL"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.whatsapp || ""}
                            invalid={
                              validation.touched.whatsapp &&
                              validation.errors.whatsapp
                                ? true
                                : false
                            }
                          />
                          {validation.touched.whatsapp &&
                          validation.errors.whatsapp ? (
                            <FormFeedback type="invalid">
                              {validation.errors.whatsapp}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={6}>
                        <div className="form-group">
                          <Label className="form-label" for="website">
                            Website Link
                          </Label>
                          <Input
                            id="website"
                            name="website"
                            autoComplete="off"
                            className="form-control"
                            placeholder="Enter Website Link"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.website || ""}
                            invalid={
                              validation.touched.website &&
                              validation.errors.website
                                ? true
                                : false
                            }
                          />
                          {validation.touched.website &&
                          validation.errors.website ? (
                            <FormFeedback type="invalid">
                              {validation.errors.website}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <Label className="form-label" for="linkedIn">
                            LinkedIn Profile
                          </Label>
                          <Input
                            id="linkedIn"
                            name="linkedIn"
                            autoComplete="off"
                            className="form-control"
                            placeholder="Enter LinkedIn Profile"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.linkedIn || ""}
                            invalid={
                              validation.touched.linkedIn &&
                              validation.errors.linkedIn
                                ? true
                                : false
                            }
                          />
                          {validation.touched.linkedIn &&
                          validation.errors.linkedIn ? (
                            <FormFeedback type="invalid">
                              {validation.errors.linkedIn}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={6}>
                        <div className="form-group">
                          <Label className="form-label" for="facebook">
                            Facebook Profile
                          </Label>
                          <Input
                            id="facebook"
                            name="facebook"
                            autoComplete="off"
                            className="form-control"
                            placeholder="Enter Facebook Profile"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.facebook || ""}
                            invalid={
                              validation.touched.facebook &&
                              validation.errors.facebook
                                ? true
                                : false
                            }
                          />
                          {validation.touched.facebook &&
                          validation.errors.facebook ? (
                            <FormFeedback type="invalid">
                              {validation.errors.facebook}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <Label className="form-label" for="instagram">
                            Instagram Profile
                          </Label>
                          <Input
                            id="instagram"
                            name="instagram"
                            autoComplete="off"
                            className="form-control"
                            placeholder="Enter Instagram Profile"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.instagram || ""}
                            invalid={
                              validation.touched.instagram &&
                              validation.errors.instagram
                                ? true
                                : false
                            }
                          />
                          {validation.touched.instagram &&
                          validation.errors.instagram ? (
                            <FormFeedback type="invalid">
                              {validation.errors.instagram}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <div className="form-group">
                      <Label className="form-label" for="description">
                        Description
                      </Label>
                      <Input
                        id="description"
                        name="description"
                        autoComplete="off"
                        className="form-control"
                        placeholder="Enter User Name"
                        type="textarea"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        invalid={
                          validation.touched.description &&
                          validation.errors.description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.description &&
                      validation.errors.description ? (
                        <FormFeedback type="invalid">
                          {validation.errors.description}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="text-center mt-4">
                      {role !== "Admin" && <Button
                        type="button"
                        className="me-3"
                        color="success"
                        onClick={e => {
                          e.preventDefault()
                          setShowCard(true)
                          return false
                        }}
                      >
                        Show Card
                      </Button>}
                      <Button type="submit" className="me-3" color="warning">
                        Update Profile
                      </Button>
                      <Link to="/profile" className="btn btn-secondary">
                        Discard Changes{" "}
                      </Link>
                    </div>
                  </Form>
                ) : (
                  <div
                    style={{ backgroundColor: "white", padding: "15px" }}
                    ref={cardRef}
                  >
                    <Row>
                      <Col md={8}>
                        <div className="ms-2 me-3">
                          <img
                            src={link}
                            alt={email}
                            className="avatar-lg rounded-circle img-thumbnail"
                          />
                        </div>
                        <CardTitle className="mb-2">
                          <h1 className="display-6">{fullName}</h1>
                        </CardTitle>
                        <h4 className="text-muted mb-2">{description}</h4>

                        <h6 className="lead text-muted">{phoneNo}</h6>
                        <h6 className="lead text-muted">{email}</h6>
                      </Col>
                      <Col md={4}>
                        <Row>
                          <QRCode
                            className="mb-4"
                            value={`${process.env.REACT_APP_FRONTEND}/public-profile/${userName}`}
                            size={100}
                          />
                          {showCard || website || whatsapp ? (
                            <Col md={12}>
                              {showCard ? (
                                <Button
                                  type="button"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      `${process.env.REACT_APP_FRONTEND}/public-profile/${userName}`
                                    )
                                  }}
                                  className="btn btn-soft-info btn-rounded"
                                >
                                  <i className="bx bx-copy"></i>
                                </Button>
                              ) : null}
                              {website ? (
                                <Button
                                  type="button"
                                  onClick={() => window.open(website, "_blank")}
                                  className="btn btn-soft-info btn-rounded"
                                >
                                  <i className="bx bx-link"></i>
                                </Button>
                              ) : null}
                              {whatsapp ? (
                                <Button
                                  type="button"
                                  onClick={() =>
                                    window.open(whatsapp, "_blank")
                                  }
                                  className="btn btn-soft-info btn-rounded"
                                >
                                  <i className="bx bxl-whatsapp"></i>
                                </Button>
                              ) : null}
                            </Col>
                          ) : null}
                          {linkedIn || facebook || instagram ? (
                            <Col md={12}>
                              {linkedIn ? (
                                <Button
                                  type="button"
                                  onClick={() =>
                                    window.open(linkedIn, "_blank")
                                  }
                                  className="btn btn-soft-info btn-rounded"
                                >
                                  <i className="bx bxl-linkedin"></i>
                                </Button>
                              ) : null}
                              {facebook ? (
                                <Button
                                  type="button"
                                  onClick={() =>
                                    window.open(facebook, "_blank")
                                  }
                                  className="btn btn-soft-info btn-rounded"
                                >
                                  <i className="bx bxl-facebook"></i>
                                </Button>
                              ) : null}
                              {instagram ? (
                                <Button
                                  type="button"
                                  onClick={() =>
                                    window.open(instagram, "_blank")
                                  }
                                  className="btn btn-soft-info btn-rounded"
                                >
                                  <i className="bx bxl-instagram"></i>
                                </Button>
                              ) : null}
                            </Col>
                          ) : null}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
