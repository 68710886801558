/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL"

/**
 * add user
 */
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"

/**
 * Edit user
 */
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

/**
 * Delete user
 */
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

/**
 * Reset user
 */
export const RESET_USER = "RESET_USER"
export const RESET_MESSAGES = "RESET_MESSAGES"

/**
 * Upload files
 */
export const UPLOAD_FILES = "UPLOAD_FILES"
export const UPLOAD_FILES_SUCCESS = "UPLOAD_FILES_SUCCESS"
export const UPLOAD_FILES_FAIL = "UPLOAD_FILES_FAIL"

/**
 * Delete files
 */
export const DELETE_FILES = "DELETE_FILES"
export const DELETE_FILES_SUCCESS = "DELETE_FILES_SUCCESS"
export const DELETE_FILES_FAIL = "DELETE_FILES_FAIL"

/**
 * Get courses
 */
export const GET_COURSES = "GET_COURSES"
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS"
export const GET_COURSES_FAIL = "GET_COURSES_FAIL"

/**
 * Update click count
 */
export const UPDATE_CLICK_COUNT = "UPDATE_CLICK_COUNT"