import React, { useEffect } from "react"
import { Spinner } from "reactstrap"

const Spinners = ({ setLoading, time = 1000 }) => {
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, time)
  }, [setLoading])

  return (
    <React.Fragment>
      <Spinner color="primary" className="position-absolute top-50 start-50" />
    </React.Fragment>
  )
}

export default Spinners
